import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Abrazo de los pueblos - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Abrazo de los pueblos"
        canonical="https://eduardoceballos.com/poemas/abrazo-de-los-pueblos"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/abrazo-de-los-pueblos",
          title: "Abrazo de los pueblos - Poemas",
          description: "Abrazo de los pueblos",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Abrazo de los pueblos</h1>
      <center>
        <p>
          Desando los caminos,
          <br />
          vuelvo por el cauce del río
          <br />a buscar la memoria
          <br />
          de tu nombre antiguo,
          <br />
          para unir paisajes
          <br />
          que asoman por el coplerío.
          <br />
          La musical chapaca
          <br />
          ternura tarijeña
          <br />
          baja cantando de las montañas
          <br />
          como una niña nueva
          <br />
          la fragante sinfonía
          <br />y procrea la vida.
          <br />
          El Guadalquivir me dice
          <br />
          de esa milenaria danza
          <br />
          que de las montañas baja
          <br />
          con el mensaje frutal de la raza.
          <br />
          Eras de plata, tierra dorada
          <br />
          alguna vez Argentina,
          <br />
          hoy de la hermana Bolivia
          <br />
          esta picante tierra de Tarija.
          <br />
          En tu escudo asoma el cielo,
          <br />
          pasan las estrellas
          <br />
          que van y vienen
          <br />
          de la misteriosa distancia
          <br />
          reflejándose en el mármol,
          <br />
          en las esbeltas cordilleras,
          <br />
          en los campos verdes,
          <br />
          en las aromadas praderas,
          <br />
          en el vino de tu valle,
          <br />
          por donde pasó el conquistador
          <br />
          con su laurel y sus hierros,
          <br />
          buscando el oro y la plata
          <br />
          que tu tierra guardaba
          <br />
          secretamente en tus entrañas.
          <br />
          Vuelvo Tarija,
          <br />
          siempre vuelvo
          <br />a percibir el canto de tus pájaros
          <br />a sentir los pasos
          <br />
          de la historia
          <br />
          junto al Moto Méndez
          <br />o a nuestro Martín Miguel de Güemes.
          <br />
          Por eso vuelvo siempre, Tarija.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
